// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea {
    color: black;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    /**not using For Now */
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 38px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    /**not using For Now */
    // background-color: $button-bg-color;
    background-color: #3475aa;
    border-color: $button-bg-color;
    color: $button-text-color;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}


//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: #3475aa !important;
    border-color: #3475aa !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #3475aa !important;
    box-shadow: none !important;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: bold;
    color: $color-template;
    cursor: pointer;
}



.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    font-family: unset;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: black;
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }
}

.p-button-danger {
    &:hover {
        background: $color-red-hover !important;
        border-color: $color-red-hover
    }
}

.ck-content {
    min-height: 30vh;
}

.p-button.p-button-icon-only {
    height: 3.2rem;
}

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.text_decoration {
    text-decoration: none;
}

.top-negative {
    top: -2.5rem;
}

.position-relative {
    position: relative;
}

.dropdown-option {
    white-space: normal;
    word-wrap: break-word;
}


.custom-text-color {
    color: rgb(0, 122, 217) !important;
    font-size: 18px;
}

/**not using For Now */
// .theme-light .container__wrap {
//     color: rgb(0, 122, 217) !important;
// }


.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #3475aa;
    color: #ffff
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #ffff
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 750px;
}

.inventory-check-tbody {
    display: block;
    max-height: 500px !important;
    overflow-y: scroll;
}

.inventory-check-thead,
.inventory-check-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.custom-outline {
    border: 1px solid;
    color: #3475aa;
    margin-left: 2rem;
}

.custom-outline:hover {
    background-color: #3475aa;
    color: #FFFFFF;
}

.dashboard-text-color {
    color: #646777;
}

.table_thead {
    vertical-align: middle !important;
}

//DashBoard
.dasboard-title {
    color: #007ad9 !important;
    padding-left: 1.5rem;
    padding-bottom: 0.5rem;
}

.table-text {
    color: #646777 !important;
}

.dashboard-icons {
    color: red;
    margin-top: 10px;
    font-size: 15px;
    cursor: pointer;
}

.add_to_orders_btn {
    margin: auto;
    padding: 0px 7px;
}

a:-webkit-any-link {
    // color: #007ad9;
    cursor: pointer;
    text-decoration: none !important;
}

.cartDisplay {
    margin-left: 0.6rem;
    display: flex;
    margin-right: 5px;
    margin-top: 0.1rem;
}

.cart_badge {
    height: fit-content;
    margin-bottom: auto;
}

.blue_Link {
    color: rgb(0, 122, 217);
}

.custom-link {
    text-decoration: underline;
    color: #3475aa;
    cursor: pointer;
}



.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 18px;
}

.p-datatable .p-datatable-tbody>td {
    text-align: center !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 8px !important;
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 6px !important;
}




//pagination custom css 
// .p-datatable .p-column-header-content {
//     padding: 4px;
//     display:flex ;
//     flex-direction: none !important;
//     text-align: center !important;
//     justify-content: center; 
// }


// .p-paginator .p-dropdown {
//      margin-left: 1rem; 
//     height: 3rem;
//     border: 0px;
//     color: #a7a8ac;
//     // font-size: 12px;
//     margin-top: 1.2px;
// }
// .p-paginator .p-dropdown .p-dropdown-label {
//     font-size: 14px;
// }

// body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
//     background-color: #3475aa;
//     color: #ffffff;
//     border-radius: 0px !important;
//     height: 2rem;
//     min-width: 2rem;
// }

.p-card {
    box-shadow: none;
}

.p-component {
    font-size: 14px;
}

// Data Tbale Header Alignment Changes
.p-datatable .p-column-header-content {
    display: block !important;
    padding: 0px 5px;
}

// Data Table Columb Body changes 
.dataTableColumnAlignments {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cartSubLocation_dropdown {
    max-width: '180px';
    height: "90%";
    line-height: 1.25;
    text-align: center;
    margin-right: 5px;
}

.actions_dropdown {
    min-width: '10%';
    line-height: 1;
    margin-top: 2px;
}

.categoryType_dropdown {
    height: 38px;
    width: 180px;
    line-height: 16px;
    margin-right: 0.5rem;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 3px;

}

.categoryType_dropdowns {
    height: 50px;
    width: 180px;
    line-height: 16px;
    margin-right: 0.5rem;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 3px;

}


.wislistdistributorCard {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin: 1px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgb(230, 229, 229);
}

.table_bg_color {
    background-color: #3475aa;
}

.table_header_data {
    font-weight: 500;
}

.p-dialog-header {
    background-color: #3475aa !important;
    color: white !important;
    height: 70px !important;
    text-align: left;
    padding-top: 0.5rem;
}


.dashboard-title {
    margin-left: 1.5rem !important;
    color: #3475aa !important;
}

.customAddIcon {
    color: white;
    padding: 3.5px;
    margin-left: 0px;
    border-radius: 22px;
    font-size: 34px;
    cursor: pointer;
    background-color: #3475aa;
}


.align_checkBox {
    margin-bottom: 1rem !important;
}


.categoryButtons {

    width: 100%;
    height: 3.19rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.headerInfostyle {
    display: flex;
    justify-content: left;
}

// form fields size changes 

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px !important;
}

//autocomplete icon
.p-button.p-button-icon-only {
    width: 2rem;
    padding: 0.75rem 0;
    height: 38px !important;
    border-radius: 0 3px 3px 0 !important;
}

// .doInput.form input, .form textarea {
.doInput input {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 38px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 3px !important;
    border: solid 1px lightgray;
}

// Multiselect 
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0px !important;
}

.react-select__single-value .template_icon {
    display: none
}

.Do_Select {
    .p-dropdown {
        border-radius: 3px !important;

        .p-dropdown-label {
            display: flex;
            align-items: center;
            width: 100%;
            padding: auto;
            font-size: 12px;
            height: 38px;
            transition: border 0.3s;
            background: transparent;
            border-radius: 3px !important;
        }

    }
}

.categoryButtons {

    width: 100%;
    height: 3.19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: auto;
    font-size: 12px;
    height: 38px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 3px !important;
    color: #495057
}



.custom-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
}

.custom-card:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.modal-dialog-right {
    // position: fixed;
    width: 90%;
    max-width: 50vh;
    max-height: 80vh;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(0);
}

.p-dropdown-item .p-highlight {
    padding: 0px !important;
}

.add_icon_change {
    height: 38px;

}

//Buttons
.modal__close-btn {

    background: transparent;
}



.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.custom-outline {
    border: 1px solid;
    color: #3475aa;
    margin-left: 0rem !important;
    margin-top: 1rem;
}


.paswwordChanges {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 38px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 3px !important;
    border: solid 1px lightgray;
    margin-left: -3px;
}


.nav-link {
    color: #3475aa !important;
    font-weight: bold;
}

.select_plan_dropdown_align {
    max-width: '300px';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    white-space: 'nowrap';
}

.mark_required {
    width: '6px' !important;
    margin-bottom: '5px' !important;
    margin-left: '2px' !important;
}




.p-dropdown .p-dropdown-label.p-placeholder {
    color: #495057;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: '#3475aa' !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: '#3475aa' !important;
    background-color: '#3475aa' !important;
}

// a:-webkit-any-link {
//     color: #3475aa !important;
// }

.p-button.p-button-outlined {
    color: #3475aa;
}

.wrap-text {
    padding-top: 22px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
}

@media screen and (max-width: 600px) {

    table,
    th,
    td {
        font-size: 12px;
        padding: 5px;
    }

    .table-text {
        font-size: 12px;
    }

    .table--bordered.table--head-accent.table.table-hover {
        width: 850px;
    }
}



.registercard-width {
    width: 40rem !important;
}

.login-card {
    width: 30rem !important;
}

.forgot_password {
    // margin-left: '7rem',
    //     margin-top:'-2rem'
    margin-left: 7rem !important;
    margin-top: -2rem !important;
}

.button_align {
    width: 100%;
    display: flex;
    justify-content: end;
}

// Add To Order List Button Focus And active for Primary And Danger Styles
.p-button.p-button-danger:enabled:focus {
    box-shadow: none !important;
    color: rgb(243, 233, 233) !important;
    background-color: rgb(232, 65, 65);
    border-color: rgb(232, 65, 65) !important;
}

.p-button.p-button-danger.p-button-outlined:enabled:active {
    box-shadow: none !important;
}

.p-button.p-button-primary:enabled:focus {
    box-shadow: none !important;
    color: rgb(243, 233, 233) !important;
    background-color: #3475aa;
    border-color: #3475aa !important;
}

.p-button.p-button-primary.p-button-outlined:enabled:active {
    box-shadow: none !important;
}


//product directry add category buttn style
.product_btn {
    height: 35px;
    margin-top: 1.4rem;
}

//Dilouge Header  &  Back icon

.p-dialog .p-dialog-header {
    height: 3.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
    color: white !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none !important;
}

.p-link:focus {
    box-shadow: none !important;
}

.p-button:focus {
    box-shadow: none !important;
}

.align-tabtypes {
    margin-bottom: 10 !important;
    display: flex !important;
    justify-content: left !important;
    padding: 2px;
    margin-bottom: 2px;
}

.tabType {
    width: 33.3% !important;
    text-align: center !important;
}

//css for auto complete field 
.p-autocomplete-panel {
    .p-autocomplete-items {
        width: 300px !important;

    }

    .p-autocomplete-item {
        white-space: unset;
        padding: 0.50rem 1rem !important;
    }
}

.drugsfield_add_icon {
    margin-left: 10px !important;
    padding: 4px !important;
    font-size: 12px !important;
    border-radius: 25px !important;
}

.topbar__center {
    margin: auto !important;
    font-size: 25px !important;
    font-weight: bold !important;
    color: #3475aa !important;
    width: 100%;
    display: flex;
    justify-content: center;
}

.drugsfield_add_icon {
    margin-left: 10px !important;
    padding: 4px !important;
    font-size: 15px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background: #3475aa !important;
    border-color: #3475aa !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: #3475aa !important;
}


.text-left .text-capitalize .text-decoration {
    padding-top: 30px !important;
}

.svg-inline--fa .fa-exclamation-triangle .fa-w-18 .dashboard-icons {
    margin-top: 22px !important;
    padding: 1rem;
}

// .svg-inline--fa.fa-w-18 {
//     height: 25px !important;
//     margin-top: 22px !important;
// }

.dashboard_button_align {
    padding-left: 2.5rem !important;
}

.dashboard_textalign {
    color: #3475aa;
    padding-top: 22px;
}

.dashboard_storageUnit {
    padding-top: 30px;
}

.align_icon {
    margin-top: 22px;
}

.p-column-filter-menu {
    // display: none !important;
    margin-left: 0px !important;
}

//Radio Buttons Bg color
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #3475aa;
    background: #3475aa;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #3475aa;
    background: #3475aa;
    color: #ffffff;
}

//Drop down selected color change

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    color: #ffffff;
    background-color: #3475aa !important;
}

.align_indication_modal {
    max-width: 800px !important;
}

.icon_align {
    color: #3475aa;
    margin-left: 5px;
}

.align_dashboard_icon {
    height: 25px !important;
    margin-top: 22px !important;
}

//payments
.payment_fields {
    background-color: #fff;
    border-color: #9c9c9c;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 7px 15px
}

.account__btns {
    display: flex;
    width: calc(100% + 30px);
    margin: -10px 0 -20px -10px;

    a {
        margin: 10px 0 20px 10px;
        white-space: nowrap;
    }
}

.payment_input {
    background-color: #fff;
    border-color: #9c9c9c;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 7px 15px;
    color: #5d6066;
    width: 100%;
    font-weight: 3000;
    font-size: 14px;
    outline: none;

    &:active &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &::-moz-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &:-moz-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }

    &:-ms-input-placeholder {
        color: #9c9c9c;
        font-weight: 500;

    }
}

.toolTipModify {
    white-space: "nowrap";
    text-overflow: ellipsis;
    overflow: hidden;
}

.status_color {
    padding-right: 1.5rem !important;
}


.Do_Select .p-dropdown.p-disabled {
    background-color: lightgray;
}

.p-multiselect {
    display: flex;
    align-items: center;
    width: 100%;
    padding: auto;
    font-size: 12px;
    height: 40px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 3px !important;
}

// .plus-icon {
//     margin-left: 0.3rem;
//     /* Adjust this value as needed */
// }

// /* If you want to use Bootstrap's default tooltips */
// .tooltip {
//     font-size: 0.8rem;
//     /* Adjust this value as needed */
// }

.fa-lg {
    font-size: small !important;
}

// .Do_Select .p-dropdown .p-dropdown-label {
//     height: 42px !important;
// }
.p-dropdown-label .p-inputtext {
    margin-top: 5px !important;
}

.Do_Select .p-dropdown .p-dropdown-label {
    height: 40px !important;
}

.FileGalleryView {
    .modal {
        display: block;
        position: fixed;
        z-index: 2000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        overflow: hidden;
    }

    .modal-content {
        margin: 5% auto;
        background-color: rgb(36, 34, 36);
        width: 70%;
        max-width: 1200px;
        padding: 50px;
        position: relative;
        overflow: hidden;
        color: #f1f1f1;
    }

    .close {
        color: #f1f1f1;
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: #ddd;
        text-decoration: none;
        cursor: pointer;
    }

    .gallery-media {
        max-width: 100%;
        height: auto;
        width: auto;
        display: block;
        margin: 0 auto;
    }

    .gallery-image-link {
        display: block;
        max-width: 100%;
    }

    .gallery-index {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #f1f1f1;
        padding: 5px;
    }

    .image-gallery-index {
        display: none;
    }

    >button {
        color: $button-text-color;
        background-color: $color-template;
        text-transform: capitalize;
        border-radius: 20px;
        font-size: 10px;
    }

    .image-gallery-play-button {
        padding: 0px !important;
    }

    .image-gallery-fullscreen-button {
        padding: 0px !important;
    }

    .image-gallery-thumbnail {
        margin-top: 10px;
        border-radius: 12px;
    }

    .image-gallery-thumbnail-image {
        margin-top: 0px;
    }
}
.p-column-filter-menu-button {
    color:white !important
}

.p-datatable .p-datatable-thead > tr > th{
    text-align: center !important;
}
// .custom-card {
//     min-height:50px; /* Increase card height */
//     width: 40%; /* Adjust width for better column layout */
//     max-width: 100px; /* Prevent card from being too wide */
//     padding: 10px; /* Increase inner spacing */
//     // font-size: 1.1rem; /* Make text larger */
//   }
  
.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;

}